<template>
  <div>
    <tab-headers>
      Hello, {{ user.firstName }}!
      <template #text> Welcome to your dashboard </template>
    </tab-headers>
    <div v-if="loading" class="flex items-center justify-center h-72">
      <a-spin />
    </div>
    <div v-else class="grid md:grid-cols-3 grid-cols-1 gap-5 mt-10">
      <DashboardCard
        title="Template Bought"
        color="bg-green-50"
        class="bg-green-50"
        go-to="template"
        :image="template"
        :value="siteData.boughtTemplates"
      />
      <DashboardCard
        title="Sales from Templates"
        color="bg-yellow-50"
        class="bg-yellow-50"
        go-to="template"
        :image="money"
        :value="siteData.salesFromTemplates"
      />
      <DashboardCard
        title="Registered Lawyer"
        go-to="account-management"
        color="bg-blue-50"
        class="bg-blue-50"
        :image="team"
        :value="siteData.registeredLawyers"
      />
      <DashboardCard
        title="Inactive Lawyer"
        color="bg-red-50"
        class="bg-red-50"
        go-to="account-management?active_tab=suspended"
        :image="teamInactive"
        :value="siteData.inactiveLawyers"
      />
      <DashboardCard
        title="Total Users"
        color="bg-amber-50"
        class="bg-amber-50"
        go-to="dashboard"
        :image="users"
        :value="siteData.users"
      />
      <DashboardCard
        title="Unsettled dispute"
        go-to="disputes"
        color="bg-blue-50"
        class="bg-blue-50"
        :image="userInactive"
        :value="siteData.unsettledDisputes"
      />
      <DashboardCard
        title="Active Subscriptions"
        go-to="dashboard"
        color="bg-amber-50"
        class="bg-amber-50"
        :image="userInactive"
        :value="siteData.activeSubscriptions"
      />
      <DashboardCard
        title="Active Lawyers"
        go-to="dashboard"
        color="bg-pink-50"
        class="bg-pink-50"
        :image="userInactive"
        :value="siteData.activeLawyers"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TabHeaders from '../../components/typography/TabHeaders.vue'
import DashboardCard from '../../components/dashboardCard.vue'
import template from '../../assets/svg/template.svg'
import money from '../../assets/svg/money.svg'
import team from '../../assets/svg/team.svg'
import teamInactive from '../../assets/svg/team_inactive.svg'
import users from '../../assets/svg/users.svg'
import userInactive from '../../assets/svg/user_inactive.svg'
import dataApi from '../../api/site_data'

export default {
  name: 'AdminDashboard',
  components: { TabHeaders, DashboardCard },
  layout: 'admindashboard',
  data() {
    return {
      template,
      money,
      team,
      teamInactive,
      users,
      userInactive,
      siteData: {},
      loading: false,
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  created() {
    this.getAdminStats()
  },
  methods: {
    async getAdminStats() {
      this.loading = true
      const req = await dataApi(this.axios).getAdminStats()
      this.siteData = req.data
      this.loading = false
    },
  },
}
</script>
