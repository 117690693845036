<template>
  <router-link
    :to="goTo"
    class="w-full h-36 shadow-md rounded-md p-3 py-5 bg-white"
  >
    <div class="flex items-start justify-between">
      <div class="flex items-center justify-between">
        <div :class="`p-2 rounded-full mr-4 border ${color}`">
          <img :src="image" class="w-6 h-6" alt="" />
        </div>
        <h3 class="text-sm font-light mb-0">{{ title }}</h3>
      </div>
    </div>
    <div
      class="
        font-bold
        text-6xl
        flex
        items-center
        justify-end
        h-full
        text-gray-600
      "
    >
      <span>{{ value }}</span>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'DashboardCard',
  props: {
    image: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    value: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      default: '',
    },
    goTo: {
      type: String,
      required: true,
      default: '/',
    },
  },
}
</script>
